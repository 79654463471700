import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Timeline(props:any) {
  return (
    
    <VerticalTimeline lineColor={ 'black' }>
       
    
    {props.data.map((el:any)=>{
        return(

            <VerticalTimelineElement
                key={el.name}
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="2011 - present"
                iconStyle={{ background: 'beige', color: '#fff' }}
                
                icon={
                <div className='h-full w-full flex justify-center items-center'>
                    <img className='h-full w-full p-2' src={el.image} alt='edu' />
                </div>
                }
            >
                <h3 className="vertical-timeline-element-title">{el.name} ({el.type})</h3>
                <h4 className="vertical-timeline-element-subtitle">{el.location}</h4>
                <ul className='mt-2'>
                    {el.details.map((val:String)=>{
                        return(
                            <li>- {val}</li>
                        )
                    })}
                </ul>
            </VerticalTimelineElement>   
        )
    })}

        <VerticalTimelineElement
            iconStyle={{ background: 'white', color: '#fff' }}
                        icon={
                        <div className='h-full w-full flex justify-center items-center'>
                            <img className='h-full w-full p-2' src={require('../../assets/favicon.png')} alt='edu' />
                        </div>
                        }
                    >


                    </VerticalTimelineElement> 
            </VerticalTimeline>



  )
}

export default Timeline