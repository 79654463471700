import Typed from "react-typed"

const profileMan = require("../../assets/man.png")

function Banner() {
    return (
        <div className="text-[20px] mobile:text-[35px] laptop:text-[50px] desktop:text-[70px] h-[calc(100vh-50px)] flex p-4 flex-col laptop:flex-row">
            <div className="flex-1 laptop:flex-2 flex flex-col justify-center items-center laptop:items-end">
                <p className="text-[20px]">Hi, I'm Ritwik Raj</p>
                <div>
                    <Typed 
                        strings={[
                            "Full Stack Developer",
                            "Frontend Designer",
                            "UI/UX Professional",
                            "Project Leader"
                        ]}
                        typeSpeed={120}
                        backSpeed={100}
                        //showCursor={false}
                        
                        loop
                    />
                </div>
            </div>            
            <div className="flex-3 laptop:flex-1 flex items-center justify-center  h-[100%] overflow-hidden">
                <img className="h-[100%]" src={profileMan} alt='trap'></img>    
            </div>
            <div className="flex-1  flex laptop:justify-start justify-center items-center">
                <a className="text-sm tablet:text-xl bg-slate-300 py-2 px-4 rounded-lg" href={require('../../assets/Ritwik_Raj_Resume.pdf')} download>Click to download</a>
            </div>
        </div>
    )
}

export default Banner