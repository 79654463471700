import React from 'react'
import Banner from '../../components/banner'
import Skills from '../../components/skills'
import SkillsA from '../../components/skills_a'
import AppLayout from '../../layout/appLayout'

function home() {
  return (
    <AppLayout>
        <Banner />
        <Skills />
        <SkillsA />
    </AppLayout>
  )
}

export default home