import React from 'react'

function Footer() {

    const logo = require('../../assets/favicon.png')

  return (
    <div className='bg-slate-200 h-[50px] flex justify-center items-center font-theme '>
        <div className='h-full p-1'>
            <img className='h-full' src={logo} alt='logo' />
        </div>
        <div className='px-1'>
            Made with love by Ritwik Raj
        </div>
    </div>
  )
}

export default Footer