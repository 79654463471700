import React from 'react'
import AppLayout from '../../layout/appLayout'

function Contact() {
  return (
    <AppLayout>
      <div className='h-[calc(100vh-150px)] flex flex-col'>
        <div className='flex text-center flex-1 justify-center items-center text-[30px]'>
          <p> Contact Page is under progress</p>
        </div>
        <div className='overflow-hidden flex-4 flex h-full justify-center items-center'>
          <img  src={require('../../assets/notfound.png')} alt='' />
        </div>
      </div>
    </AppLayout>
  )
}

export default Contact