import React from 'react'
import Timeline from '../../components/timeline';
import AppLayout from '../../layout/appLayout';
import { EDUCATION } from '../../constants';

function Education() {
  return (
    <AppLayout>
        <div className='h-[80px] px-4 flex justify-start laptop:justify-center items-center text-[30px]'>
          <p>Education</p>
        </div>
        <Timeline data={ EDUCATION } />
    </AppLayout>
  )
}

export default Education