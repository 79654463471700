import React, { useState } from 'react'
import { HEADER_LIST } from '../../constants'
import { useNavigate } from "react-router-dom";

const menu = require('../../assets/menu.png')

function Header() {
  const navigate = useNavigate();

  const [menuVisibility, setMenuVisibility] = useState(false);

  const showMenu=()=>{
    setMenuVisibility(!menuVisibility)
  }

  const navigator=(el:any)=>{
    showMenu()
    navigate(el)
  }

  return (
    <div>
      <div className='fixed w-full h-[50px] bg-white top-0 px-4 z-[100] flex flex-row shadow-md justify-between tracking-wide font-theme'>
        <div className='text-[30px] leading-[50px]'>
          <p>Ritwik.<span className=''>R()</span></p>
        </div>
        
        <div className='text-[20px] leading-[50px]'>
          <div className='hidden laptop:block'>
            {HEADER_LIST.map((el)=>{
              return(
              <p onClick={() => navigate(el.route)} key={el.title} className='mx-3 capitalize inline-block cursor-pointer hover:text-slate-400'>{el.title}</p>
              )
            })}
          </div>

          <div className='block laptop:hidden py-[10px]' >
            <img onClick={showMenu} className='h-[30px]' src={menu} alt="menu" />
          </div>
        </div>
      </div>

      {menuVisibility?
      <div className='laptop:hidden bg-slate-200 text-[30px] z-50 fixed w-full  h-[calc(100%-50px)] font-theme flex flex-col justify-evenly items-center'>
        {HEADER_LIST.map(el=>{
          return(
            <div onClick={()=>navigator(el.route)} key={el.title} className='capitalize'>
              {el.title}
            </div>
          )
        })}

      </div>
      :null
      }
    </div>
  )
}

export default Header