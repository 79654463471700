import React from 'react'
import { SKILLS } from '../../constants'
import { Tooltip } from 'react-tooltip'

function Skills() {

  const poster = require('../../assets/skill.png')

  return (
    <div className='h-[calc(100vh-50px)] flex flex-col p-4'>
      <div className='h-[40px] laptop:h-[100px] flex justify-center items-center'>
        <p className='text-[20px] mobile:text-[30px] laptop:text-[50px]'>
          My Skillsets
        </p>
      </div>
      <div className='h-[calc(100%-40px)] laptop:h-[calc(100%-100px)] flex flex-col laptop:flex-row'>
        <div className='h-full flex-1 min-h-[50px] overflow-hidden'>
          <img className='object-contain w-full h-full' src={poster} alt='skills' />
        </div>
        <div className=' h-full flex-1 flex flex-col'>
          <div className='text-slate-500 flex-1 flex justify-center items-center text-[25px] laptop:text-[40px] text-center'>
              <p>Full Stack Development</p>
          </div>
          <div className='flex-4 flex justify-center flex-wrap content-evenly'>
            {SKILLS.map(el=>{
              return(
                <div key={el.title} className='h-[60px] w-[60px] mobile:h-[65px] mobile:w-[65px] tablet:h-[75px] tablet:w-[75px] laptop:h-[120px] laptop:w-[120px]'>
                  <img id={el.title} className='h-full w-full p-4' src={el.logo} alt={el.title} />
                  <Tooltip anchorId={el.title} content={el.title} place="top" />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
     
  )
}

export default Skills