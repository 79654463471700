import React from 'react'
import { SKILLS_A , SKILLS_B} from '../../constants'
import { Tooltip } from 'react-tooltip'

function SkillsA() {

  return (
   
      <div className='h-[calc(100vh-50px)] p-4 flex flex-col tablet:flex-row laptop:flex-col'>
        
        <div className='h-full flex-1 flex flex-col justify-evenly'>
          <div className='text-slate-500 flex justify-center items-center text-[25px] laptop:text-[40px] text-center'>
              <p>Cloud Infra-Architecture</p>
          </div>
          <div className='flex justify-center flex-wrap content-evenly'>
            {SKILLS_A.map(el=>{
              return(
                <div key={el.title} className='h-[60px] w-[60px] mobile:h-[65px] mobile:w-[65px] tablet:h-[75px] tablet:w-[75px] laptop:h-[120px] laptop:w-[120px]'>
                  <img id={el.title} className='h-full w-full p-4' src={el.logo} alt={el.title} />
                  <Tooltip anchorId={el.title} content={el.title} place="top" />
                </div>
              )
            })}
          </div>
          <div className='text-center'>
            <p>-  Experience working on multiple cloud platforms</p>
            <p>-  Experience hosting and managing websites</p>
            <p>-  Experience with Continuous Integration</p>
          </div>
        </div>

        <div className='h-full flex-1 flex flex-col justify-evenly'>
          <div className='text-slate-500 flex justify-center items-center text-[25px] laptop:text-[40px] text-center'>
              <p>Project Management</p>
          </div>
          <div className=' flex justify-center flex-wrap content-evenly'>
            {SKILLS_B.map(el=>{
              return(
                <div key={el.title} className='h-[60px] w-[60px] mobile:h-[65px] mobile:w-[65px] tablet:h-[75px] tablet:w-[75px] laptop:h-[120px] laptop:w-[120px]'>
                  <img id={el.title} className='h-full w-full p-4' src={el.logo} alt={el.title} />
                  <Tooltip anchorId={el.title} content={el.title} place="top" />
                </div>
              )
            })}
          </div>
          <div className='text-center'>
            <p>-  1.5 Yrs Experience as a team leader</p>
            <p>-  Managed, Planned and co-ordinated multiple projects</p>
            <p>-  Good communication and leadership skills</p>
          </div>
        </div>

      </div>
  
     
  )
}

export default SkillsA