import React from 'react'
import Cards from '../../components/cards'
import AppLayout from '../../layout/appLayout'
import { CERTIFICATIONS } from '../../constants'

function Certifications() {
  return (
    <AppLayout>
        <div className='h-[100px] flex justify-center items-center text-[30px]'>
          Certifications
        </div>
        <div className='flex justify-evenly flex-wrap'>
          {CERTIFICATIONS.map(el=>{
            return(
              <Cards data={el}/>
            )
          })}
          
        </div>
        
    </AppLayout>
  )
}

export default Certifications