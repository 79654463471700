import React from 'react'
import Timeline from '../../components/timeline';
import AppLayout from '../../layout/appLayout';
import { EXPERIENCE } from '../../constants';

function Experience() {
  return (
    <AppLayout>
      <div className='h-[80px] px-4 flex justify-start laptop:justify-center items-center text-[30px]'>
        <p>Experience</p>
      </div>
      <Timeline data={EXPERIENCE}/>
    </AppLayout>
  )
}

export default Experience