import { createHashRouter } from "react-router-dom";
import Certifications from "../pages/certifications";
import Contact from "../pages/contact";
import Education from "../pages/education";
import Experience from "../pages/experience";
import Home from "../pages/home";
import Projects from "../pages/projects";

const RootRoutes = createHashRouter([
    {
      path: "/",
      children: [
        {
            path:'/',
            element : <Home />
        },
        {
          path:'/experience',
          element : <Experience />
        },
        {
          path:'/education',
          element : <Education />
        },
        {
          path:'/certifications',
          element : <Certifications />
        },
        {
          path:'/projects',
          element : <Projects />
        },
        {
          path:'/contact',
          element : <Contact />
        },
      ]
    }
])

export default RootRoutes;