import React from 'react'

function Cards(props:any) {
  return (
    <div className='p-5 w-[200px] mobile:w-[100%] desktop:w-[500px]'>
        <div className='p-4 rounded-2xl overflow-hidden shadow-2xl'>
            <div className='h-[120px] flex justify-center py-[10px]'>
                <img className='h-full' src={props.data.logo} alt='skill' />
            </div>
            <div className='text-center border-t-2 py-2'>
                <p className='text-slate-500 text-[20px]'>{props.data.name}</p>
                <p className='text-slate-500 text-[15px]'>( {props.data.tenure} {props.data.type} )</p>
                <p className='text-slate-400 text-[25px]'>{props.data.description}</p>
            </div>
        </div>
    </div>
  )
}

export default Cards