export const HEADER_LIST = [
    { title: "home", label: "home", route:'/' },
    { title: "Education", label: "Education", route:'/education' },
    { title: "Certifications", label: "Certifications", route:'/certifications' },
    { title: "Experience", label: "Experience", route:'/experience' },
    { title: "Projects", label: "Projects",route:'/projects' },
    { title: "Contact", label: "Contact",route:'contact' },
  ];

export const SKILLS =[
  {title:'angular', logo:require('../assets/skills/angular.png')},
  {title:'react', logo:require('../assets/skills/react.png')},
  {title:'javascript', logo:require('../assets/skills/javascript.png')},
  {title:'python', logo:require('../assets/skills/python.png')},
  {title:'html', logo:require('../assets/skills/html.png')},
  {title:'css', logo:require('../assets/skills/css.png')},
  {title:'redux', logo:require('../assets/skills/redux.png')},
  {title:'typescript', logo:require('../assets/skills/typescript.png')},
  {title:'node', logo:require('../assets/skills/node.png')},
  {title:'mongo', logo:require('../assets/skills/mongodb.png')},
  {title:'mysql', logo:require('../assets/skills/mysql.png')},
  {title:'postman', logo:require('../assets/skills/postman.png')},
  {title:'figma', logo:require('../assets/skills/figma.png')},
  {title:'sass', logo:require('../assets/skills/sass.png')},
  {title:'tailwind', logo:require('../assets/skills/tailwind.png')},
]

export const SKILLS_A =[
  {title:'aws', logo:require('../assets/skills/aws.png')},
  {title:'netlify', logo:require('../assets/skills/netlify.png')},
  {title:'heroku', logo:require('../assets/skills/heroku.png')},
]

export const SKILLS_B =[
  {title:'slack', logo:require('../assets/skills/slack.png')},
  {title:'jira', logo:require('../assets/skills/jira.png')},
  {title:'Excel', logo:require('../assets/skills/excel.png')},
]

export const EXPERIENCE =[
  {name:'Raaika', type:'Internship', location:'Kolkata', details:['JavaScript Developer', 'Shopify Management'],image:require('../assets/raaika.png')},
  {name:'Edifixio India Pvt Ltd', type:'full-time', location:'Kolkata', details:['MEAN Stack Software Developer' , 'User Experience', 'Visual Design'],image:require('../assets/edifixio.png')},
  {name:'SmDevops', type:'full-time', location:'Noida', details:['Full Stack Developer' , 'Creative Direction',  'Project Lead' , 'Strategy'],image:require('../assets/smdevops.png') },
  {name:'IDS Infotech', type:'full-time', location:'Chandigarh', details:['Angular Developer' , 'Creative Direction', 'Visual Design'],image:require('../assets/ids.png')}
]

export const EDUCATION =[
  {name:'St. Teresa School', type:'Matriculation, ICSE', location:'Bhagalpur', details:['Active participation in various extracurricular activities', 'School Football team captain for 1 year'], image:require('../assets/teresa.png')},
  {name:'Techno International School', type:'Senior Secondary Education, CBSE', location:'Bhagalpur', details:['Active participation in various extracurricular activities', 'School Football team captain for 1 year'], image:require('../assets/tms.png')},
  {name:'Narula Institute Of Technology', type:'Graduation', location:'Kolkata', details:['Studied core subjects like Data Structures, DBMS, Networking, Security, etc.', 'Completed various online courses for Backend , Website Development, etc.','Implemented several projects based on technologies learned during the course period',], image:require('../assets/nit.png')},
]

export const CERTIFICATIONS = [
  {name:'Python', logo:require('../assets/skills/python.png'), tenure:'2 month' , type:'Training' ,description:'Microsoft Imagine Academy'},
  {name:'Python', logo:require('../assets/skills/javascript.png'), tenure:'2 month' , type:'Internship' ,description:'Areforyou Pvt. Ltd. (Raaika) '},
  {name:'Python', logo:require('../assets/skills/node.png'), tenure:'1 month' , type:'Training' ,description:'Udemy by Quentin Watt'},
  {name:'Python', logo:require('../assets/tata.png'), tenure:'' , type:'Marathon' ,description:'Tata Annual Marathon'},
]

