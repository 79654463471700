import React, { ReactNode } from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'

interface IDefaultLayout {
    children: ReactNode
}

const AppLayout: React.FC<IDefaultLayout> = ({ children }) => {
    return (
      <div className=" relative]">
        <Header />
        <div className='font-theme mt-[50px] h-[calc(100%-100px)]'>
          { children }
        </div>
        <Footer />
      </div>
    )
  }

export default AppLayout